@media (max-width: 480px){
    .secondS-sub-container {
        width: 90%;
        display: block; 
        text-align: center;
    }
    .second-bg {
       min-height: 100vh;
    }
    .secondS-sub-container p {
        font-size: 1.6rem !important;
        width: 90%;
        margin-right: 1rem;
    }
    .secondS-sub-container img{
        width: 90%;
        
    }
}


@media (max-width: 1669px){
    .secondS-sub-container p{
        font-size: 4.5rem;
    }
}
@media (max-width: 1557px){
    .secondS-sub-container p{
        font-size: 3.5rem;
    }
}
@media (max-width: 1369px){
    .secondS-sub-container {
        width: 50%;
        flex-wrap: wrap !important;
        text-align: center !important;
        justify-content: center !important;
    }
    .secondS-sub-container img{
        width: 90%;
    }

  
    .secondS-sub-container p{
        margin-top: 1rem;
        font-size: 3rem !important;
        width: 70% !important;

    }
}


@media (max-width: 1263px){
    .second-bg{
      min-height: 100vh !important;
    }
    .secondS-sub-container {
        width: 60% !important;
        flex-wrap: wrap !important;
        text-align: center !important;
        justify-content: center !important;
    }
    .secondS-sub-container img{
        width: 100% !important;
    }
    .secondS-sub-container p{
        margin-top: 1rem;
        font-size: 3rem !important;
        width: 100% !important;

    }
}
@media (max-width: 1024px){
    .secondS-sub-container p{
        font-size: 2.5rem !important;
    }
    .middle-footer ul li{
        font-size: 1.2rem !important;
        
    }
  
}

@media (max-width: 820px){
    .secondS-sub-container {
        width: 70% !important;
    }
    .hadith{
        padding: 3rem !important;
    }
    .contact {
        margin-top: 3rem;
    }
    .middle-footer{
        font-size: 1.1rem !important;
        
    }
    

}

@media (max-width: 715px){
    .secondS-sub-container {
        width: 80% !important;
    }
}


