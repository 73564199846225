* {
    box-sizing: border-box;
}
.navbar-toggler::after {
    content: 'القائمة' !important;
}
.lang-list {
    width: 15%;
    justify-content: center;
    margin: auto;
    column-gap: 15px;
    color: white;

}

.lang-list ul {
    text-align: center !important;
}

.lang-list li {
    text-align: center;
    margin: auto;
    color: white !important;
    cursor: pointer;
}

nav a {
    color: white;
    margin: 10px;
}

ul li a {

    color: white !important;
}

.navbar-container {
    position: relative;
    padding: 10px;
    align-items: center;
    background-color: rgb(0, 0, 0);
    margin: auto;
    overflow: hidden;
    z-index: 1;
}

.nav-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.bg {
    position: absolute;
    background-size: cover;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.4 !important;
    z-index: -1;
    object-fit: cover;
    object-position: bottom;
}

.NavLink {
    transition: 0.3s;
    font-size: 1.4rem;
    color: rgb(255, 209, 2);

}

.NavLink:hover {
    color: rgb(255, 209, 2);
    /* color: rgb(187, 168, 1); */
    transform: scale(1.1);

}

@media (min-width: 661px) {
    .nav-in-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .bg {
        min-height: 700px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap;
    }

    .navbar>.container-fluid {
        justify-content: center
    }
}

@media (max-width: 660px) {
    .nav-in-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .bg {
        min-height: 700px !important;
    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap;
    }

    .navbar>.container-fluid {
        justify-content: center
    }

}

@media (max-width: 480px) {

    .nav-in-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .nav-ul li {
        margin: 0rem;
        display: list-item;
        color: white;
    }



    .container-fluid {
        width: 350px !important;
        padding: 10px 0;
    }

    .bg {
        min-width: 600px !important;
        object-position: bottom;

    }

    .navbar-nav {
        display: flex !important;
        flex-wrap: wrap;
    }

    .navbar>.container-fluid {
        justify-content: center
    }
}