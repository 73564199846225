.about-container {
    position: relative;
    background-color: rgb(0, 0, 0);
    /* background: rgb(139, 85, 2); */
    background: linear-gradient(90deg, rgb(73, 69, 11) 0%, rgb(117, 84, 1) 92%);
    background: linear-gradient(90deg, rgb(206, 93, 0) 0%, rgb(160, 117, 0) 92%);
    overflow: hidden;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 3rem;
    z-index: 1;
}

.about-container img {
    position: absolute;
    background-size: cover;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.8;
    z-index: -1;
    width: 100%;

}

.bg-about {
    position: absolute;
    background-size: cover;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.29 !important;
    z-index: -1;
}

.about-sub-container h1 {
    font-size: 3rem;
    font-weight: 600;
}

.about-sub-container h4 {
    line-height: 150%;
}

.hadith h1 {
    line-height: 150%;
}

.hadith h3 {
    color: rgb(255, 166, 0);
    color: rgb(0, 146, 0);

}

.hadith {
    padding: 7rem;
}

.hadith h3:nth-child(2) {
    color: rgb(255, 166, 0);

}

#hello-student {
    color: rgb(0, 146, 0);
}

.tazkia div {
    text-align: center;
}

.tazkia img {
    width: 50%;
}

.tazkia-title {
    color: rgb(0, 150, 125);
    padding-top: 4rem;
    text-align: center;
    line-height: 150%;
}

.voice {
    width: 50%;
}

/*  */

@media (max-width: 768px) {
    .bg-about {
        min-height: 700px;
    }

    .voice {
        width: 70%;
    }
    .tazkia-title {
        color: rgb(0, 150, 125);
        padding: 2rem;
        text-align: center;
        line-height: 150%;
    }
}

@media (max-width: 480px) {
    .secondS-sub-container {
        text-align: center;
        width: 100% !important;
    }
    .tazkia-title {
        color: rgb(0, 150, 125);
        padding: 2rem;
        text-align: center;
        line-height: 150%;
    }
    .tazkia img {
        width: 90%;
    }
    .voice {
        width: 80%;
    }

    .about-container img {
        min-height: 1000px;
    }

    .about-sub-container {
        margin: auto;
    }

    .about-sub-container h4 {
        width: 95%;
    }

    .about-container {
        padding: 1rem 0;
    }

    .hadith {
        padding: 1rem;
    }

    .hadith {
        width: 100%;
        padding: 1rem;
    }

    .hadith h1 {
        font-size: 1.2rem;
    }

}