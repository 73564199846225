.prog-container {
    position: relative;
    background-color: rgb(0, 135, 224);
    background-color: rgb(0, 165, 157);

    /* background: rgb(139, 85, 2); */
    /* background: linear-gradient(90deg, rgb(73, 69, 11) 0%, rgb(117, 84, 1) 92%);
    background: linear-gradient(90deg, rgb(206, 93, 0) 0%, rgb(160, 117, 0) 92%); */
    overflow: hidden;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 3rem;
    z-index: 1;
}

.bg-prog {
    position: absolute;
    background-size: cover;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.4;
    z-index: -1;
    width: 100%;
    /* background-color: black; */
}

.prog-sub {
    display: flex;
    justify-content: space-around;
    margin: auto;
    align-items: center;
    justify-items: center;
}



.right-side h1 {
    font-size: 8vh;
    font-weight: 500;
}

.more {
    background: #ffffff;
    /* fallback for old browsers */
    color: rgb(0, 116, 116);
    padding: 1rem;
    border-radius: 25px;
    font-size: 1.4rem;
    margin-top: 2rem !important;
    border: 1px solid white;
    font-weight: 600;
}

.more:hover{
    background-color: rgb(0, 87, 77);
    color: white;
    outline: none;
    border: none;
}
.slider-container {
    z-index: 300;
    /* display: block; */
    width: 50%;
}
.react-multi-carousel-item{
    height: 120%;
}

.cars-img {
    width: 100%;
    height: 120%;
    display: flex;
    gap: 1rem;
}

.cars-img img {
    width: 100%;
    height: 120%;

}
.cars-img img.zoomed {
    transform: scale(1.5);
  }

  .hadith h1:nth-child(2){
    font-weight: 600;
  }
.ul-list li{
    color: black !important;
}

  @media (max-width: 992px){
    .slider-container {
        z-index: 300;
        /* display: block; */
        width: 30%;
    }
    .cars-img {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 1rem;
    }
  }
  /*  */

  @media (max-width: 768px){
    .prog-sub{
        flex-wrap: wrap;
    }
    .react-multi-carousel-list  {
        padding-top: 2rem;
    }
    .right-side h1{
        margin-left: 1rem;
        font-size: 3.2rem;
    }
    .bg-prog{
        min-height:700px;
    }
    .react-multiple-carousel__arrow::before{
        font-size: 13px;

    }
    .react-multiple-carousel__arrow {
        min-width: 23px;
        min-height: 23px;
       transform: translateY(20px);
        
  }
  .cars-img{
    width: 100%;
    height: ;

  }
  .cars-img img {
    width: 204%;
  }
  }
  @media (max-width: 480px){
    .right-side h1{
        font-size: 2rem;
    }
    .prog-sub{ 
        display: block;

    }
    .bg-prog{
        min-height:900px;
    }
    .cars-img{
        margin-top: 2rem;
        text-align: center;
        width: 100%;
        justify-content: center;
    }
    .react-multi-carousel-list{
        flex-wrap: wrap;
    }
    .slider-container {
        width: 100%;
       
    }
    .cars-img img{
        height: auto;
        width: 120%;
    }
    .hadith{
        
        padding: 1.1rem !important;
    }
    .hadith h4{
        margin: 0rem;
      
    }
    .more{
        font-size: 1rem;
    }

    .react-multiple-carousel__arrow {

    }
  }


