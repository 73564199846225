* {
    box-sizing: border-box;
}

:root {
    --gold: linear-gradient(to bottom, rgba(168, 99, 35, 0.975), rgba(192, 115, 1, 0.925), rgba(255, 169, 56, 0.986));
}

.login-logo {
    position: relative;
    width: 100%;
    text-align: center;
    cursor: pointer;
    z-index: 100;

}

.login-logo img {
    width: 10%;
}


.form-container {
    position: relative;
    height: 100vh;
}

.bg-login {
    position: absolute;
    background-size: cover;
    top: 0;
    height: 110vh;
    width: 100%;
    z-index: 1;
    background-position: center;
    opacity: 0.4;
}
.ifram iframe{
    width: 100%;
    margin: auto;
    /* margin-right: 3rem; */
    height: 160vh;
    border: none;
}

.form {
    position: relative;
    margin: auto;
    background: rgb(255, 255, 255, 0.4);
    width: 25%;
    z-index: 100;
    border: 2px solid rgb(255, 153, 0);
    border-radius: 15px;
    padding: 1rem;
    display: block;

    gap: 1rem;
    justify-content: center;

}
.form-select:focus{
    /* border: none !important; */
    /* border: 2px solid rgb(255, 166, 0); */
    box-shadow: none;    
}
.form-select{
    width: 100% !important;
}
.form input , #country,.form-select{
    font-size: 1.3rem;
    padding: 0.5rem 2.5rem;
    margin: auto;
    width: 90%;
    height: 50px;
    z-index: 100;
    border-radius: 10px;
    outline: none;
    border: 2px solid rgb(255, 166, 0);
}

.form input::placeholder {
    font-size: 1.1rem;

}

.form input:focus {
    outline: none;
    border: 0.5pt solid rgb(0, 0, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inp-with-icon {
    margin: auto;

}

.inp-with-icon {
    position: relative;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

}

.inp-with-icon svg {
    width: 15px;
    position: absolute;
    top: 15px;
    right: 20px;
    fill: rgb(255, 166, 0);
    z-index: 120;
}



.submit {
    font-size: 1.1rem !important;
    background: linear-gradient(to bottom, rgba(168, 99, 35, 0.975), rgba(235, 154, 33, 0.925), rgba(190, 130, 52, 0.986));
    color: white;
    border: none;
    border-radius: 15px;
}

.submit:hover {
    background: linear-gradient(to bottom, rgba(168, 99, 35, 0.975), rgba(192, 115, 1, 0.925), rgba(255, 169, 56, 0.986));
}



/* RESPONSIVE */

@media (max-width: 2000px) {
    .login-logo img {

        margin-top: 2rem;
    }
    .form-select{
        width: 100% !important;
    }
}


@media (min-width: 1100px){
    .inp-with-icon svg {
        position: absolute;
        top: 15px;
        right: 40px !important;
   
    }
    .form-select{
        width: 100% !important;
    }
}

@media (min-width: 993px) {
    .login-logo img {
        width: 40%;
    } 
    .form{
        width:40%;
    }
    .inp-with-icon svg {
        width: 15px;
        position: absolute;
        top: 15px;
        right: 30px;
        fill: rgb(255, 166, 0);
        z-index: 120;
    }
    .form-select {
        width: 90% !important;
    }
}

@media (max-width: 992px) {
    .login-logo img {
        width: 40%;
    } 

    .form{
        width: 50%;
    }
    .inp-with-icon svg {
        right: 40px;
    }
    .form-select {
        width: 90% !important;
    }
}

@media (max-width: 793px){
    .login-logo img {
        width: 40%;
    }   
    .form {
        width: 55%;
        margin: auto;
        margin-top: 3rem;
    }
    .inp-with-icon svg {
        right: 30px;
    }

    .form-select {
        width: 90% !important;
    }
}

@media (max-width: 768px) {
    
    .login-logo img {
        width: 40%;
    } 
    .inp-with-icon svg {
        top: 15px;
        right: 20px;
    }

    .form {
        width: 65%;
        margin: auto;
        margin-top: 3rem;
    }

    .form input {
        width: 100%;
        margin: auto !important;
    }

    .inp-with-icon {
        margin: auto !important;
        /* margin-left: 20vw !important; */
        margin-top: 1rem !important;
    }
    .form-select{
        width: 100% !important;
    }

}

@media (max-width: 576px) {
    .login-logo img {
        width: 60%;
    }

    .form {
        margin-top: 3rem;
        width: 70%;
    }
    .form-select{
        width: 100% !important;
    }
}

@media (min-width: 1450px) {
    .login-logo img {
        width: 30%;
        margin-top: 1rem;
    }

    .form {
        width: 35%;
        margin: auto;
        margin-top: 0rem;
    }
    .form input {
        font-size: 1.3rem;
        padding: 0.5rem 2.5rem;
        margin: auto;
        width: 100%;
        height: 50px;
        z-index: 100;
        border-radius: 10px;
        outline: none;
        border: 2px solid rgb(255, 166, 0);
    }

    .inp-with-icon {
        margin: auto !important;
        margin-top: 1rem !important;
    }
    .inp-with-icon svg {
        width: 15px;
        position: absolute;
        top: 15px;
        right: 10px !important;
        fill: rgb(255, 166, 0);
        z-index: 120;
    }
    .form-select{
        width: 100% !important;
    }
}

@media (min-width: 1669px){
.form{
    width: 25%;
}
}