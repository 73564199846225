.prog h1 {
    width: fit-content;
    padding-bottom: 10px;
    margin: auto;
    color: rgb(255, 0, 0);
    /* border-bottom: 2px solid black;     */
}

.prog h3 {
    color: rgb(255, 153, 0);
    font-weight: 500;
}

.ul-list li {
    list-style-type: decimal !important;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    margin-right: 2rem;
}

.ul-list {
    /* border-bottom: 4px dashed rgb(255, 145, 0); */
    padding-bottom: 3rem;
}


.odd {
    border-bottom: none;
}
.accordion-button {
    font-size: 1.3rem !important;
    font-weight: 500 !important; 
    color: green !important;
}
.accordion-button::after {
    margin-right: 1rem !important;
}
.accordion-header:focus{
    background-color: red !important;
}

.price{
    padding-bottom: 5px;
    width: fit-content;
    border-bottom: 1px solid red;
}

@media (max-width:480px) {
    .prog h1{
        font-size: 1.5rem;
    }
    .accordion-header button{
        font-size: 1.2rem !important;
    }
    .accordion-body ul li{
        font-size: 1.2rem;
    }
}