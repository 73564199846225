* {
    margin: 0;
    padding: 0;
}

.footer {
    position: relative;
    background: black;
    color: white !important;
    list-style: disc !important;
    width: 100%;
    align-items: center !important;
    overflow: hidden;
    z-index: 100;

}

.bg-footer {
    position: absolute;
    background-size: cover;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15 !important;
    z-index: -1;
    width: 100%;
}

.footer-in {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr ; */
    display: grid;
    /* grid-template-columns: 33% 33% 33%; */
    grid-template-columns: 1fr 1fr 1fr ;
    /* grid-column: 3; */
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: auto;

}


.logo-footer {
    width: 70%;
    fill: white;
    margin: auto;
}

.middle-footer {
    width: 100%;
    margin: auto;
    /* margin-right: 2rem; */
    font-size: 1.3rem;
    color: white !important;
}



.markz {
    width: 100%;
    line-height: 150%;
    text-align: center;
    /* background-color: rgb(255, 255, 255); */
    color: rgb(255, 255, 255);
    font-weight: 300;
}

.markz h6 {
    line-height: 180%;
}

.logo-with-markz {
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-items: center;
    margin: auto;
    text-align: center;

}

.number {
    direction: ltr;
    font-size: 1.6rem !important;

}

.fixed-icons {
    /* background-color: rgb(218, 218, 218); */
    padding: 0.8rem;
    border-radius: 30px;
    /* border: 2px solid rgb(255, 102, 0); */
}

.fixed-icons img {
    width: 55px;
    margin-right: 10px;
    transition: 0.3s;
    cursor: pointer;
}

.fixed-icons img:hover {
    transform: translateY(-7px);
}

.work-time h3{
    color: rgb(255, 153, 0);
}

.map{
    margin-top: 2rem;
    border-radius: 20px;
    border: 2px solid yellow;
}


@media (max-width: 992px) {
   
    .middle-footer{
        /* transform: translate(10%); */
        font-size: 1.3rem !important;
    }
    /* .footer-in{
       display: grid;
       grid-template-columns: 1fr 1fr;
       margin: auto;
       text-align: center;
    } */
    .locaion {
        transform: translate(30%);
        margin-right: 6rem;
        border: 5px solid yellow;
    }

}

@media (max-width: 768px) {
    .footer-in {
        /* display: grid;
        grid-template-columns: 1fr; */
        display: block;
        justify-content: center !important;
        align-items: center !important;
        padding: 1rem;
        overflow: hidden;
    }

    .logo-with-markz {
        margin: auto;
        text-align: center;
    }

    .markz {
        width: 100%;
        margin: auto;
    }

    .middle-footer {
        transform: translate(0%);
    }

    .bg-footer {
        min-height: 1100px;
    }

    .locaion {
        transform: translateY(10%);
    }
}

@media (max-width: 480px) {

    .bg-footer {
        min-height: 1200px;
    }

    .markz {
        width: 100%;
        line-height: 200%;
    }

    .markz h6 {
        width: 100%;
        line-height: 200%;
        margin: auto;
        font-weight: 500 !important;
        font-size: 1.2rem !important;

    }

    .footer-in {
        gap: 40px;
    }
}

@media (max-width: 430px){


    .middle-footer{
        transform: translate(0%);
    }

}


@media (max-width: 1203px) {
    .middle-footer {
    }
    .footer-in {
        /* display: grid;
        grid-template-columns: 1fr 1fr 1fr ; */
        display: block;
        grid-template-columns: 33% 33%;
        /* grid-column: 3; */
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: auto;
        text-align: center;
    }
    .bg-footer {
        min-height: 1400px;
    }
    .contact {
        margin-top: 3rem ;
    }
    .locaion{
        transform: translate(-30%);
        border: 3px solid red;
    }


}
