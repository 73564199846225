.secondS-main-container {
    position: relative;
    /* background-color: rgb(255, 255, 255); */
    z-index: 3;
    height: fit-content;
    overflow: hidden;
}

.secondS-sub-container {
    width: 90%;
    margin-top: 3rem;
    margin: auto;
    align-items: center;
}

.secondS-sub-container p {
    font-size: 3rem;
    width: 50%;
    color: rgb(0, 0, 0);
    font-weight: 600;
    text-align: center;
    font-weight: 700;
}

.secondS-sub-container img {
    width: 60%;
    text-align: center;

}

.second-bg {
    position: absolute;
    width: 100%;
    opacity: 1;
    background-size: contain;
    z-index: -1;
    margin-top: 0rem;
}

/* RESPONSIVE */

@media (max-width: 1263px){
    .second-bg{
      min-height: 100vh !important;
    }
    .secondS-sub-container {
        width: 60% !important;
        flex-wrap: wrap !important;
        text-align: center !important;
        justify-content: center !important;
    }
    .secondS-sub-container img{
        width: 100% !important;
    }
    .secondS-sub-container p{
        margin-top: 1rem;
        font-size: 3rem !important;
        width: 100% !important;

    }
}

@media (max-width: 992px){
    .secondS-sub-container{
        text-align: center;
        width: 100% !important;
    }
    .main-logo{
        width: 100% !important;
    }
}


@media (max-width: 768px){

    .secondS-sub-container{
        text-align: center;
        width: 100% !important;
    }
    .main-logo{
        width: 100% !important;
    }
    .secondS-sub-container p{
        font-size: 2rem;
        width: 80%;
        margin-top: 3rem;
        text-align: center;
    }
    .second-bg{
     margin-top: 20vh;
    }
}

@media (max-width: 480px) {
    .secondS-sub-container{
        text-align: center;
        width: 100% !important;
    }
    .main-logo{
        width: 100% !important;
    }
  
    .second-bg {
        min-height: 100vh;
    }

    .secondS-sub-container p {
        font-size: 1.6rem !important;
        width: 90%;
        margin-right: 1rem;
    }

    .secondS-sub-container img {
        width: 100%;

    }
    
}
